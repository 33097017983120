.overlay-container {
    height: 100vh;
    width: 100vw;
    display: flex;
}

.overlay-options {
    height: 100%;
    width: 35%;
    background-color: #2F3136;
    flex: 0 0 auto;
    position: relative;
}

.overlay-options-list {
    width: fit-content;
    position: absolute;
    right: 0;
    margin-top: 100px;
    margin-right: 5px;
}

.overlay-options-list > div {
    border-radius: 3px;
    margin-top: 5px;
}

.overlay-name {
    color: #8E9297;
    font-size: 16px;
    margin: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 200px;
}

.overlay-overview-selector {
    color: white;
    background-color: #393C43;
    padding: 5px;
}

.overlay-delete-button {
    color: #ED4143;
    padding: 5px;
}

.overlay-delete-button:hover {
    background-color: #34373C;
    cursor: pointer;
}

.overview-header {
    color: white;
    font-weight: normal;
    margin-bottom: 20px;
}

.overlay-info {
    background-color: #36393F;
    height: 100%;
    flex: 1 1 auto;
    position: relative;
    padding-left: 50px;
    padding-right: 500px;
    padding-top: 100px;
    padding-bottom: 50px;
}

.close-settings {
    position: absolute;
    right: 350px;
    top: 100px;
    color: #8E9297;
    border: 1px solid;
    border-radius: 50%;
    padding: 10px;
    height: 40px;
    width: 40px;
    font-size: 30px;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-settings:hover {
    cursor: pointer;
}
