@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200&display=swap');

.channel-settings-form {
    position: relative;
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
}

.channel-settings-name-label,
.channel-settings-topic-label {
    color: #B9BBBE;
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-top: 20px;
    position: relative;
}

.channel-settings-name-input {
    margin-top: 5px;
    background-color: #303339;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    padding-left: 10px;
    transition: .3s;
    width: 100%;
}

.channel-settings-name-input:hover,
.channel-settings-name-input:focus {
    border: 1px solid #00AFF4;
}

.channel-edit-errors {
    font-size: 14px;
    color: #fd5f5f;
    margin-top: 10px;
}

.channel-settings-topic-textarea {
    margin-top: 5px;
    background-color: #303339;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    transition: .3s;
    width: 100%;
    padding: 5px 10px;
    resize: none;
}

.topic-character-counter,
.channel-name-character-counter {
    color: #9B979A;
    font-family: 'Inconsolata', monospace;
    font-size: 15px;
    position: absolute;
    bottom: 3px;
    right: 0;
}

.character-length-exceeded-true {
    color: #fd5f5f;
}

.channel-settings-save-button {
    background-color: #45C06C;
    color: white;
    width: 120px;
    margin: 0 auto;
    margin-top: 30px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.channel-settings-disabled-true {
    background-color: #97bba3;
    color: #d8d8d8;
    cursor: not-allowed;
}
