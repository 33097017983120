.new-server-image-container {
    border: 3px dashed white;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
}

.new-server-image-container:hover {
    cursor: pointer;
}

.image-selected-true {
    border: none;
}

.new-server-photo-icon {
    color: white;
}

.new-server-image-text {
    color: white;
    text-align: center;
}

.new-server-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.new-image-plus-icon {
    color: white;
    background-color: #5865F2;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    position: absolute;
    top: -3px;
    right: -3px;
}
