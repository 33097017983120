.new-channel-form-container {
    border: 1px solid;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 22rem;
    padding: 30px 60px;
    background-color: #36393F;
    border-radius: 10px; 
}

.new-channel-header {
    text-align: center;
    margin-bottom: 20px;
    color: white;
}

.new-channel-name-label {
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 15px;
    position: relative;
}

.new-channel-name-input {
    margin-top: 5px;
    background-color: #303339;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    padding-left: 10px;
    padding-right: 25px;
    transition: .3s;
}

.new-channel-name-input:focus,
.new-channel-name-input:hover {
    border: 1px solid #00AFF4;
}

.channel-error-container {
    margin-top: 5px;
    white-space: pre;
}

.channel-error {
    color: #fd5f5f;
}

.new-channel-name-character-counter {
    color: #9B979A;
    font-family: 'Inconsolata', monospace;
    font-size: 15px;
    position: absolute;
    bottom: 3px;
    right: 5px;
}

.new-channel-character-length-exceeded-true {
    color: #fd5f5f;
}

.new-channel-cancel-submit-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
}

.new-channel-cancel-button {
    color: white;
    margin-right: 20px;
}

.new-channel-cancel-button:hover {
    cursor: pointer;
}

.new-channel-submit-button {
    background-color: #5865F2;
    color: white;
    width: 130px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
}

.new-channel-submit-button:hover {
    cursor: pointer;
    background-color: #4752C4;
}

.new-channel-disabled-true {
    background-color: rgb(147, 155, 241);
    color: rgb(185, 189, 236);
}

.new-channel-disabled-true:hover {
    background-color: #9199f1;
    cursor: not-allowed;
}
