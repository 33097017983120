.signup-background {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

.signup-form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid;
    width: fit-content;
    display: flex;
    flex-direction: column;
    width: 26rem;
    padding: 30px 60px;
    background-color: #36393F;
    border-radius: 10px;
}


.signup-error {
    color: #fd5f5f;
    font-size: 14px;
    margin-top: 5px;
}

.signup-error-container {
    width: 325px;
    margin: 0 auto;
}

.signup-header {
    text-align: center;
    margin-bottom: 20px;
    color: white;
}

.signup-email-label,
.signup-username-label,
.signup-password-label,
.signup-confirm-label {
    display: flex;
    flex-direction: column;
    width: 325px;
    color: white;
    margin: 0 auto;
    margin-top: 15px;
}

.signup-email-input,
.signup-username-input,
.signup-password-input,
.signup-confirm-input {
    margin-top: 5px;
    background-color: #303339;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    padding-left: 10px;
    transition: .3s;
}

.signup-confirm-input {
    margin-bottom: 20px;
}

.signup-email-input:focus,
.signup-username-input:focus,
.signup-password-input:focus,
.signup-confirm-input:focus {
    border: 1px solid #00AFF4;
}

.signup-profile-label {
    background-color: #303339;
    position: relative;
    width: 247px;
    height: 33px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    transition: .3s;
}

.picture-added-true {
    background-color: #00AFF4;
    border-color: #00AFF4;
    color: #303339;
}

.signup-profile-input {
    width: 100%;
    position: absolute;
    top: 3px;
    left: 5px;
    opacity: 0;
    z-index: -1;
}

.signup-profile-label:hover {
    border-color: #00AFF4;
    cursor: pointer;
}

.signup-button {
    background-color: #5865F2;
    color: white;
    width: 325px;
    margin: 0 auto;
    margin-top: 30px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
}

.signup-button:hover {
    cursor: pointer;
    background-color: #4752C4;
}

.signup-tologin-container {
    width: 325px;
    margin: 0 auto;
    margin-top: 10px;
}

.signup-tologin-label {
    color: #969696;
    font-size: 14px;
    display: inline;
}

.signup-tologin-link {
    margin-left: 8px;
    color: #00AFF4;
    font-size: 14px;
}

.signup-tologin-link:hover {
    text-decoration: underline;
}
