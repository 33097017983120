@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=Roboto&display=swap');

.landing-background {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(0deg, rgba(123,132,235,1) 0%, rgba(92,103,237,1) 50%, rgba(64,78,237,1) 100%);
    position: absolute; 
    z-index: -5;
}

.landing-clouds-svg {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

.landing-graphic-left-svg {
    width: 40%;
    height: fit-content;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.landing-graphic-right-svg {
    width: 40%;
    height: fit-content;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.landing-welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 100;
}

.landing-header {
    font-family: 'Paytone One', sans-serif;
    color: white;
    font-size: 48px;
    width: 58rem;
    text-align: center;
    margin-top: 25vh;
    z-index: 1000;
}

.landing-sub-header {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
    width: 30vw;
}

.landing-action-button {
    padding: 15px 25px;
    border-radius: 50px;
    background-color: #23272A;
    transition: 0.3s;
    color: white;
    font-size: 22px;
    margin-top: 40px;
}

.landing-action-button:hover {
    box-shadow: 0px 10px 48px -5px rgba(0, 0, 0, 0.30);
    background-color: #36393F;
    z-index: 100;
}

.about-links-container {
    display: flex;
    align-items: center;
    width: 300px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-45%);
}

.about-links-dev-name {
    color: white;
}

.about-links-image {
    width: 30px;
    height: 30px;
    margin-left: 20px;
}
