.server-button-holder {
    width: fit-content;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.white-line-true {
    position: absolute;
    left: 0;
    top: 25px;
    width: 4px;
    height: 25px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: white;
}

.white-line-active-true {
    position: absolute;
    left: 0;
    top: 18px;
    width: 4px;
    height: 40px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: white;   
}

.server-button-container {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #36393F;
    margin-top: 10px;
    transition: .3s;
}

.server-button-container:hover {
    cursor: pointer;
    background-color: #5865F2;
    border-radius: 17px;
}

.server-button-container-active {
    background-color: #5865F2;
    border-radius: 17px;    
}

.server-button-no-pic-content {
    font-size: 18px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: .3s;
    color: #dcdff3;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.server-button-no-pic-content:hover {
    color: #ffffff;
}

.server-button-no-pic-active {
    color: #ffffff;
}

.server-button-pic-content {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
    text-align: center;
}
