.login-background {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

.login-form-container {
    border: 1px solid;
    width: fit-content;
    display: flex;
    flex-direction: column;
    width: 26rem;
    padding: 30px 60px;
    background-color: #36393F;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-errors {
    color: #fd5f5f;
    font-size: 14px;
    margin-top: 5px;
}

.login-credentials-error-container {
    width: fit-content;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
}

.login-credentials-error {
    font-size: 14px;
}

.login-header {
    text-align: center;
    margin-bottom: 20px;
    color: white;
}

.login-credential-label,
.login-password-label {
    display: flex;
    flex-direction: column;
    width: 325px;
    color: white;
    margin: 0 auto;
    margin-top: 15px;
}

.login-credential-input,
.login-password-input {
    margin-top: 5px;
    background-color: #303339;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    padding-left: 10px;
    transition: .3s;
}

.login-credential-input:focus,
.login-password-input:focus {
    border: 1px solid #00AFF4;
}

.login-button {
    background-color: #5865F2;
    color: white;
    width: 325px;
    margin: 0 auto;
    margin-top: 30px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
}

.login-button:hover {
    cursor: pointer;
    background-color: #4752C4;
}

.login-tosignup-container,
.login-todemo-container {
    width: 325px;
    margin: 0 auto;
    margin-top: 10px;
}

.login-tosignup-label,
.login-todemo-label {
    color: #969696;
    font-size: 14px;
    display: inline;
}

.login-tosignup-link,
.login-todemo-link {
    margin-left: 8px;
    color: #00AFF4;
    font-size: 14px;
}

.login-tosignup-link:hover,
.login-todemo-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.demo-user-options-container {
    display: flex;
    flex-direction: column;
    background-color: #232529;
    height: 400px;
    width: 600px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
}

.demo-user-header {
    color: rgba(255, 255, 255, 0.925);
    margin-top: 35px;
}

.demo-user-choices-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 50px;
}

.demo-user-container {
    position: relative;
    transition: 0.3s;
    filter: brightness(30%);
}

.demo-user-container:hover {
    cursor: pointer;
    transform: scale(1.05);
    filter: brightness(100%);
}

.demo-user-container > p {
    opacity: 0;
    transition: 0.3s;
}

.demo-user-container:hover > p {
    opacity: 1;
}

.demo-user-container:hover > img {
    filter: blur(0);
}

.demo-user-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.demo-user-username {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 24px;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    text-shadow: 2px 2px 1px rgb(0, 0, 0);
}
