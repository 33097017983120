.friends-content-container {
    height: 100%;
    width: 100%;
}

.friends-content-navigator {
    border-bottom: solid 1px #202225;
    display: flex;
    height: 55px;
    padding: 15px;
    align-items: center;
}

.navigator-friends-icon {
    color: #6B6F76;
}

.navigator-friends-button-text {
    color: white;
    font-weight: 500;
    font-size: 17px;
}

.navigator-divider {
    height: 25px;
    border-left: 1px solid #6b6f7665;
    margin-left: 15px;
}

.friends-link-container {
    margin-left: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.friends-navigator-link {
    color: #B9BBBE;
}

.friends-link-container:hover {
    background-color: #3A3E44;
    cursor: pointer;
}

.friend-category-true {
    background-color: #464952;
}

.friend-link-true {
    color: white;
}

.friends-pending-container {
    display: flex;
}

.pending-requests-count {
    margin-left: 8px;
    background-color: #ED4245;
    padding: 3px;
    height: 20px;
    width: 20px;
    color: white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.add-friend-container {
    background-color: #3BA55D;
    color: white;
    margin-left: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.add-friend-container:hover {
    cursor: pointer;
}

.add-friend-category-true {
    background-color: #3ba55d00;
    color: #3BA55D;
}

.add-friend-category-true:hover {
    background-color: #3A3E44;
}

.friends-lists-container {
    padding: 20px;
    height: 70%;
}

.online-friends-container,
.all-friends-container,
.pending-friends-container {
    height: 100%;
    overflow: auto;
}

.online-friends-container::-webkit-scrollbar,
.all-friends-container::-webkit-scrollbar,
.pending-friends-container::-webkit-scrollbar {
    background-color: rgba(255, 0, 0, 0);
    width: 10px;
}

.online-friends-container::-webkit-scrollbar-thumb,
.all-friends-container::-webkit-scrollbar-thumb,
.pending-friends-container::-webkit-scrollbar-thumb {
    background-color: #232529;
    margin-left: 2px;
    border-radius: 100px;
}

.friends-status-header {
    font-size: 13.5px;
    color: #889294;
    font-weight: 500;
    margin-bottom: 10px;
}

.no-friend-requests-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-friend-header {
    color: white;
    font-weight: 500;
    font-size: 18px;
    width: 80%;
    margin: 0 auto;
}

.add-friend-message {
    color: #B9BBBE;
    font-size: 15px;
    width: 80%;
    margin: 20px auto;
}

.add-friend-form {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.add-friend-input {
    height: 50px;
    width: 100%;
    border-radius: 10px;
    background-color: #303339;
    padding-left: 10px;
    border: 1px solid rgb(29, 29, 29);
    color: #D2D3D4;
}

.add-friend-input:focus {
    border: 1px solid #00AFF4;
}

.friend-search-results {
    max-height: 80%;
    overflow: auto;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
}

.friend-search-results::-webkit-scrollbar {
    background-color: rgba(255, 0, 0, 0);
    width: 10px;
}

.friend-search-results::-webkit-scrollbar-thumb {
    background-color: #232529;
    margin-left: 2px;
    border-radius: 100px;
}

.friend-search-result {
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid;
}

.add-friend-button {
    margin-left: auto;
    height: 30px;
    padding: 0 15px;
    font-size: 14px;
    background-color: #5865F2;
    color: white;
    font-weight: 500;
    border-radius: 3px;
    margin-right: 15px;
}

.cancel-friend-request-button {
    margin-left: auto;
    height: 30px;
    padding: 0 15px;
    font-size: 14px;
    background: none;
    color: white;
    font-weight: 500;
    border-radius: 3px;
    margin-right: 15px;
}

.add-friend-button:hover,
.cancel-friend-request-button:hover {
    cursor: pointer;
}
