@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Paytone+One&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Paytone+One&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Vidaloka&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Vidaloka&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Vidaloka&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@300&family=Paytone+One&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@300&family=Paytone+One&family=Roboto:wght@100;300;400&display=swap);
/* TODO Add site wide styles */
/* site wide styles */
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/* User interaction
 * ========================================================================== */

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-family: 'Roboto', sans-serif;
    color: #3E3E3E;
}

h1 {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: black;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #D2D3D4;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

div {
    box-sizing: border-box;
}

.loading-background {
    height: 100vh;
    width: 100vw;
    background-color: #272A31;
    display: flex;
    justify-content: center;
    align-items: center;
}

#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color:rgba(255, 255, 255, 0);
}

.new-server-image-container {
    border: 3px dashed white;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
}

.new-server-image-container:hover {
    cursor: pointer;
}

.image-selected-true {
    border: none;
}

.new-server-photo-icon {
    color: white;
}

.new-server-image-text {
    color: white;
    text-align: center;
}

.new-server-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.new-image-plus-icon {
    color: white;
    background-color: #5865F2;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    position: absolute;
    top: -3px;
    right: -3px;
}

.signup-background {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

.signup-form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border: 1px solid;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    width: 26rem;
    padding: 30px 60px;
    background-color: #36393F;
    border-radius: 10px;
}


.signup-error {
    color: #fd5f5f;
    font-size: 14px;
    margin-top: 5px;
}

.signup-error-container {
    width: 325px;
    margin: 0 auto;
}

.signup-header {
    text-align: center;
    margin-bottom: 20px;
    color: white;
}

.signup-email-label,
.signup-username-label,
.signup-password-label,
.signup-confirm-label {
    display: flex;
    flex-direction: column;
    width: 325px;
    color: white;
    margin: 0 auto;
    margin-top: 15px;
}

.signup-email-input,
.signup-username-input,
.signup-password-input,
.signup-confirm-input {
    margin-top: 5px;
    background-color: #303339;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    padding-left: 10px;
    transition: .3s;
}

.signup-confirm-input {
    margin-bottom: 20px;
}

.signup-email-input:focus,
.signup-username-input:focus,
.signup-password-input:focus,
.signup-confirm-input:focus {
    border: 1px solid #00AFF4;
}

.signup-profile-label {
    background-color: #303339;
    position: relative;
    width: 247px;
    height: 33px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    transition: .3s;
}

.picture-added-true {
    background-color: #00AFF4;
    border-color: #00AFF4;
    color: #303339;
}

.signup-profile-input {
    width: 100%;
    position: absolute;
    top: 3px;
    left: 5px;
    opacity: 0;
    z-index: -1;
}

.signup-profile-label:hover {
    border-color: #00AFF4;
    cursor: pointer;
}

.signup-button {
    background-color: #5865F2;
    color: white;
    width: 325px;
    margin: 0 auto;
    margin-top: 30px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
}

.signup-button:hover {
    cursor: pointer;
    background-color: #4752C4;
}

.signup-tologin-container {
    width: 325px;
    margin: 0 auto;
    margin-top: 10px;
}

.signup-tologin-label {
    color: #969696;
    font-size: 14px;
    display: inline;
}

.signup-tologin-link {
    margin-left: 8px;
    color: #00AFF4;
    font-size: 14px;
}

.signup-tologin-link:hover {
    text-decoration: underline;
}

.login-background {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

.login-form-container {
    border: 1px solid;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    width: 26rem;
    padding: 30px 60px;
    background-color: #36393F;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.login-errors {
    color: #fd5f5f;
    font-size: 14px;
    margin-top: 5px;
}

.login-credentials-error-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
}

.login-credentials-error {
    font-size: 14px;
}

.login-header {
    text-align: center;
    margin-bottom: 20px;
    color: white;
}

.login-credential-label,
.login-password-label {
    display: flex;
    flex-direction: column;
    width: 325px;
    color: white;
    margin: 0 auto;
    margin-top: 15px;
}

.login-credential-input,
.login-password-input {
    margin-top: 5px;
    background-color: #303339;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    padding-left: 10px;
    transition: .3s;
}

.login-credential-input:focus,
.login-password-input:focus {
    border: 1px solid #00AFF4;
}

.login-button {
    background-color: #5865F2;
    color: white;
    width: 325px;
    margin: 0 auto;
    margin-top: 30px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
}

.login-button:hover {
    cursor: pointer;
    background-color: #4752C4;
}

.login-tosignup-container,
.login-todemo-container {
    width: 325px;
    margin: 0 auto;
    margin-top: 10px;
}

.login-tosignup-label,
.login-todemo-label {
    color: #969696;
    font-size: 14px;
    display: inline;
}

.login-tosignup-link,
.login-todemo-link {
    margin-left: 8px;
    color: #00AFF4;
    font-size: 14px;
}

.login-tosignup-link:hover,
.login-todemo-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.demo-user-options-container {
    display: flex;
    flex-direction: column;
    background-color: #232529;
    height: 400px;
    width: 600px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
}

.demo-user-header {
    color: rgba(255, 255, 255, 0.925);
    margin-top: 35px;
}

.demo-user-choices-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 50px;
}

.demo-user-container {
    position: relative;
    transition: 0.3s;
    -webkit-filter: brightness(30%);
            filter: brightness(30%);
}

.demo-user-container:hover {
    cursor: pointer;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    -webkit-filter: brightness(100%);
            filter: brightness(100%);
}

.demo-user-container > p {
    opacity: 0;
    transition: 0.3s;
}

.demo-user-container:hover > p {
    opacity: 1;
}

.demo-user-container:hover > img {
    -webkit-filter: blur(0);
            filter: blur(0);
}

.demo-user-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.demo-user-username {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 24px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    text-shadow: 2px 2px 1px rgb(0, 0, 0);
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 20px;
}

.navbar-logo-container {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 40px;
  width: 55px;
}

.navbar-header {
  color: white;
  font-size: 26px;
  font-style: normal;
  font-weight: normal;
  line-height: 35px;
  margin-left: 10px;
  font-family: 'Paytone One', sans-serif;
}

.navbar-login {
  padding: 10px 15px;
  border-radius: 50px;
  background-color: white;
  transition: 0.3s;
}

.navbar-login:hover {
  box-shadow: 0px 10px 48px -5px rgba(0, 0, 0, 0.30);
  color: #5865F2;
}

/* .logout-button {
  color: rgb(250, 250, 250);
  background-color: rgb(255, 95, 95);
  padding: 8px 11px;
  border-radius: 5px;
} */

.landing-background {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(0deg, rgba(123,132,235,1) 0%, rgba(92,103,237,1) 50%, rgba(64,78,237,1) 100%);
    position: absolute; 
    z-index: -5;
}

.landing-clouds-svg {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

.landing-graphic-left-svg {
    width: 40%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.landing-graphic-right-svg {
    width: 40%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.landing-welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 100;
}

.landing-header {
    font-family: 'Paytone One', sans-serif;
    color: white;
    font-size: 48px;
    width: 58rem;
    text-align: center;
    margin-top: 25vh;
    z-index: 1000;
}

.landing-sub-header {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
    width: 30vw;
}

.landing-action-button {
    padding: 15px 25px;
    border-radius: 50px;
    background-color: #23272A;
    transition: 0.3s;
    color: white;
    font-size: 22px;
    margin-top: 40px;
}

.landing-action-button:hover {
    box-shadow: 0px 10px 48px -5px rgba(0, 0, 0, 0.30);
    background-color: #36393F;
    z-index: 100;
}

.about-links-container {
    display: flex;
    align-items: center;
    width: 300px;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-45%);
            transform: translateX(-45%);
}

.about-links-dev-name {
    color: white;
}

.about-links-image {
    width: 30px;
    height: 30px;
    margin-left: 20px;
}

.new-server-button-holder {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
}

.new-server-button-container {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #36393F;
    margin-top: 20px;
    transition: .3s;
}

.new-server-button-container:hover {
    cursor: pointer;
    background-color: #3BA55D;
    border-radius: 17px;
}


.new-server-button-plus-icon {
    font-size: 46px;
    color: #3BA55D;
    border-radius: 50%;
    width: 100%;
    text-align: center;
    transition: .3s;
}

.new-server-button-plus-icon:hover {
    color: #FFFFFF;
    border-radius: 20px;
}

.new-server-form-container {
    background-color: #36393F;
    border: 1px solid;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 26rem;
    padding: 30px 60px;
    background-color: #36393F;
    border-radius: 10px;
}


.new-server-header {
    text-align: center;
    margin-bottom: 20px;
    color: white;
}

.new-server-name-label {
    display: flex;
    flex-direction: column;
    width: 325px;
    color: white;
    margin: 0 auto;
    margin-top: 15px;
    position: relative;
}

.new-server-name-input {
    margin-top: 5px;
    background-color: #303339;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    padding-left: 10px;
    padding-right: 25px;
    transition: .3s;
}

.new-server-name-input:focus {
    border: 1px solid #00AFF4;
}

.new-server-name-character-counter {
    color: #9B979A;
    font-family: 'Inconsolata', monospace;
    font-size: 15px;
    position: absolute;
    bottom: 3px;
    right: 5px;
}

.new-server-character-length-exceeded-true {
    color: #fd5f5f;
}

.server-error-container {
    margin-top: 5px;
    white-space: pre;
}

.server-error {
    color: #fd5f5f;
}

.new-server-button {
    background-color: #5865F2;
    color: white;
    width: 325px;
    margin: 0 auto;
    margin-top: 30px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
}

.new-server-button:hover {
    cursor: pointer;
    background-color: #4752C4;
}

.new-server-disabled-true {
    background-color: #9199f1;
}

.new-server-disabled-true:hover {
    background-color: #9199f1;
    cursor: not-allowed;
}

.server-button-holder {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.white-line-true {
    position: absolute;
    left: 0;
    top: 25px;
    width: 4px;
    height: 25px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: white;
}

.white-line-active-true {
    position: absolute;
    left: 0;
    top: 18px;
    width: 4px;
    height: 40px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: white;   
}

.server-button-container {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #36393F;
    margin-top: 10px;
    transition: .3s;
}

.server-button-container:hover {
    cursor: pointer;
    background-color: #5865F2;
    border-radius: 17px;
}

.server-button-container-active {
    background-color: #5865F2;
    border-radius: 17px;    
}

.server-button-no-pic-content {
    font-size: 18px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: .3s;
    color: #dcdff3;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.server-button-no-pic-content:hover {
    color: #ffffff;
}

.server-button-no-pic-active {
    color: #ffffff;
}

.server-button-pic-content {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
    text-align: center;
}

.server-settings-container {
    height: 100px;
    width: 200px;
    position: absolute;
    bottom: -60px;
    right: 5px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #18191C;
    padding: 5px 10px;
    border-radius: 5px;
}

.server-settings-button {
    color: #D2D3D4;
    padding: 8px 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.server-settings-button:hover,
.server-settings-button:hover > span {
    background-color: #4752C4;
    color: white;
}

.server-settings-icon {
    margin-left: auto;
    color: #D2D3D4;
}

.test-container {
    height: 100vh;
    width: 100vw;
    background-color: red;
}

.server-settings-form {
    position: relative;
    width: 100%;
    height: 95%;
    display: flex;
}

.server-settings-pic-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5865F2;
    position: relative;
}

.server-settings-pic-container:hover {
    cursor: pointer;
}

.server-settings-info-pic {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.server-settings-info-no-pic {
    font-size: 28px;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: .3s;
    color: white;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.change-icon-message {
    position: absolute;
    font-size: 10px;
    font-weight: 600;
    color: white;
    background-color: #000000a4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.insert-photo-icon {
    position: absolute;
    right: 0;
    top: 0;
    color: #DCDDDE;
}

.server-settings-icon-label {
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.server-settings-icon-file-input {
    position: absolute;
    width: 20px;
    bottom: 1000px;
}

.remove-image-button {
    color: white;
    border: 1px solid;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 60px;
    margin-left: 30px;
    white-space: pre;
}

.remove-image-button:hover {
    cursor: pointer;
}

.server-settings-name-label {
    color: #B9BBBE;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
}

.server-settings-name-input {
    margin-top: 5px;
    background-color: #303339;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    padding-left: 10px;
    padding-right: 25px;
    transition: .3s;
    width: 320px;
}

.server-settings-name-input:hover,
.server-settings-name-input:focus {
    border: 1px solid #00AFF4;
}

.server-edit-name-character-counter {
    color: #9B979A;
    font-family: 'Inconsolata', monospace;
    font-size: 15px;
    position: absolute;
    bottom: 3px;
    right: 5px;
}

.server-edit-character-length-exceeded-true {
    color: #fd5f5f;
}

.server-edit-errors {
    font-size: 14px;
    color: #fd5f5f;
    margin-top: 10px;
    position: absolute;
    bottom: -20px;
}

.server-settings-save-button {
    background-color: #45C06C;
    color: white;
    width: 120px;
    margin: 0 auto;
    margin-top: 30px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.disabled-true {
    background-color: #97bba3;
    color: #d8d8d8;
    cursor: not-allowed;
}

.channel-settings-form {
    position: relative;
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
}

.channel-settings-name-label,
.channel-settings-topic-label {
    color: #B9BBBE;
    display: flex;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 20px;
    position: relative;
}

.channel-settings-name-input {
    margin-top: 5px;
    background-color: #303339;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    padding-left: 10px;
    transition: .3s;
    width: 100%;
}

.channel-settings-name-input:hover,
.channel-settings-name-input:focus {
    border: 1px solid #00AFF4;
}

.channel-edit-errors {
    font-size: 14px;
    color: #fd5f5f;
    margin-top: 10px;
}

.channel-settings-topic-textarea {
    margin-top: 5px;
    background-color: #303339;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    transition: .3s;
    width: 100%;
    padding: 5px 10px;
    resize: none;
}

.topic-character-counter,
.channel-name-character-counter {
    color: #9B979A;
    font-family: 'Inconsolata', monospace;
    font-size: 15px;
    position: absolute;
    bottom: 3px;
    right: 0;
}

.character-length-exceeded-true {
    color: #fd5f5f;
}

.channel-settings-save-button {
    background-color: #45C06C;
    color: white;
    width: 120px;
    margin: 0 auto;
    margin-top: 30px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.channel-settings-disabled-true {
    background-color: #97bba3;
    color: #d8d8d8;
    cursor: not-allowed;
}

.delete-server-form {
    border: 1px solid;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 22rem;
    padding: 30px 60px;
    background-color: #36393F;
    border-radius: 10px;
}

.delete-server-header {
    color: white;
    font-size: 20px;
    font-weight: normal;
    font-weight: 600;
    text-shadow: 1px 1px 0px rgb(0, 0, 0);
}

.delete-server-confirm-message {
    color: white;
    font-size: 16px;
    margin-top: 30px;
}

.delete-server-confirm-message-name {
    color: white;
    font-weight: 600;
    text-shadow: 1px 1px 0px rgb(0, 0, 0);
}

.delete-server-enter-name-label {
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 15px;
}

.delete-server-enter-name-input {
    margin-top: 5px;
    background-color: #303339;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    padding-left: 10px;
    transition: .3s;
}

.delete-server-enter-name-input:hover,
.delete-server-enter-name-input:focus {
    border: 1px solid #00AFF4;
}

.delete-server-cancel-submit-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
}

.delete-server-cancel {
    color: white;
    margin-right: 20px;
}

.delete-server-cancel:hover {
    cursor: pointer;
}

.delete-server-button {
    background-color: #ED4143;
    color: white;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
}

.delete-server-button:hover {
    cursor: pointer;
}

.delete-server-disabled-true {
    background-color: #fa8383;
    color: rgb(224, 190, 190);
}

.delete-server-disabled-true:hover {
    cursor: not-allowed;
}

.delete-channel-form {
    border: 1px solid;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 22rem;
    padding: 30px 30px 10px;
    background-color: #36393F;
    border-radius: 10px;
}

.delete-channel-header {
    color: white;
    font-size: 26px;
    font-weight: normal;
    font-weight: 600;
    text-shadow: 1px 1px 0px rgb(0, 0, 0);
}

.delete-channel-confirm-message {
    color: #FFFFFF;
    margin-top: 30px;
}

.delete-channel-name {
    color: white;
    font-weight: 600;
}


.delete-channel-cancel-submit-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: auto;
}

.delete-channel-cancel {
    color: white;
    margin-right: 20px;
}

.delete-channel-button {
    background-color: #ED4143;
    color: white;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
}

.delete-channel-cancel:hover,
.delete-channel-button:hover {
    cursor: pointer;
}

.delete-channel-error {
    color: #fd5f5f;
}

.overlay-container {
    height: 100vh;
    width: 100vw;
    display: flex;
}

.overlay-options {
    height: 100%;
    width: 35%;
    background-color: #2F3136;
    flex: 0 0 auto;
    position: relative;
}

.overlay-options-list {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    right: 0;
    margin-top: 100px;
    margin-right: 5px;
}

.overlay-options-list > div {
    border-radius: 3px;
    margin-top: 5px;
}

.overlay-name {
    color: #8E9297;
    font-size: 16px;
    margin: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 200px;
}

.overlay-overview-selector {
    color: white;
    background-color: #393C43;
    padding: 5px;
}

.overlay-delete-button {
    color: #ED4143;
    padding: 5px;
}

.overlay-delete-button:hover {
    background-color: #34373C;
    cursor: pointer;
}

.overview-header {
    color: white;
    font-weight: normal;
    margin-bottom: 20px;
}

.overlay-info {
    background-color: #36393F;
    height: 100%;
    flex: 1 1 auto;
    position: relative;
    padding-left: 50px;
    padding-right: 500px;
    padding-top: 100px;
    padding-bottom: 50px;
}

.close-settings {
    position: absolute;
    right: 350px;
    top: 100px;
    color: #8E9297;
    border: 1px solid;
    border-radius: 50%;
    padding: 10px;
    height: 40px;
    width: 40px;
    font-size: 30px;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-settings:hover {
    cursor: pointer;
}

.new-channel-form-container {
    border: 1px solid;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 22rem;
    padding: 30px 60px;
    background-color: #36393F;
    border-radius: 10px; 
}

.new-channel-header {
    text-align: center;
    margin-bottom: 20px;
    color: white;
}

.new-channel-name-label {
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 15px;
    position: relative;
}

.new-channel-name-input {
    margin-top: 5px;
    background-color: #303339;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    padding-left: 10px;
    padding-right: 25px;
    transition: .3s;
}

.new-channel-name-input:focus,
.new-channel-name-input:hover {
    border: 1px solid #00AFF4;
}

.channel-error-container {
    margin-top: 5px;
    white-space: pre;
}

.channel-error {
    color: #fd5f5f;
}

.new-channel-name-character-counter {
    color: #9B979A;
    font-family: 'Inconsolata', monospace;
    font-size: 15px;
    position: absolute;
    bottom: 3px;
    right: 5px;
}

.new-channel-character-length-exceeded-true {
    color: #fd5f5f;
}

.new-channel-cancel-submit-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
}

.new-channel-cancel-button {
    color: white;
    margin-right: 20px;
}

.new-channel-cancel-button:hover {
    cursor: pointer;
}

.new-channel-submit-button {
    background-color: #5865F2;
    color: white;
    width: 130px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
}

.new-channel-submit-button:hover {
    cursor: pointer;
    background-color: #4752C4;
}

.new-channel-disabled-true {
    background-color: rgb(147, 155, 241);
    color: rgb(185, 189, 236);
}

.new-channel-disabled-true:hover {
    background-color: #9199f1;
    cursor: not-allowed;
}

.channel-name-container {
    color: #6B6F76;
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 3px;
    margin-top: 3px;
}

.channel-name-container:hover {
    background-color: #393C43;
    cursor: pointer;
}

.channel-settings-container {
    margin-left: auto;
    width: 20px;
    height: 20px;
}

.channel-name-hashtag {
    color: #6B6F76;
    margin-right: 5px;
    font-family: 'Vidaloka', serif;
    font-size: 20px;
}

.channel-name {
    color: #6B6F76;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
}

.channel-name-container:hover > p {
    color: white;
}

.channel-name:hover {
    cursor: pointer;
}

.channel-settings-icon {
    color: #B9BBBE;
    font-size: 20px;
}

.channel-settings-icon:hover {
    cursor: pointer;
}

.channel-selected-true {
    background-color: #393C43;
}

.channel-selected-true > p {
    color: white;
}

.channel-welcome-message-container {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    justify-content: flex-end;
    padding-left: 20px;
}

.channel-welcome-hashtag {
    width: 68px;
    height: 68px;
    font-size: 48px;
    color: white;
    border-radius: 50%;
    background-color: #4F545C;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Vidaloka', serif;
}

.channel-welcome-header,
.channel-welcome-name {
    color: white;
    font-weight: bolder;
}

.channel-welcome-header {
    margin-top: 15px;
}

.channel-welcome-subheader {
    color: #B9BBBE;
    margin-top: 15px;
}

.channel-welcome-edit-channel-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding: 8px;
    border-radius: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.channel-welcome-edit-channel-container:hover {
    cursor: pointer;
    background-color: #3A3E44;
}

.channel-welcome-edit-channel-icon,
.channel-welcome-edit-channel {
    color: #00AFF4;
}

.channel-welcome-edit-channel-icon {
    font-size: 17px;
}

.channel-welcome-edit-channel {
    margin-left: 5px;
}

.track-to-prevent-hover-above {
    /* border: 1px solid red; */
    position: absolute;
    right: 20px;
    top: -20px;
    padding-left: 60px;
    z-index: 2;
}

.message-popup-container {
    background-color: #36393F;
    /* border: 1px solid red; */
    display: flex;
    border-radius: 3px;
    border: 1px solid #2a2c31;
}

.message-popup-container:hover {
    box-shadow: 0px 7px 5px -3px rgba(0, 0, 0, 0.58);
}

.message-edit-icon,
.message-delete-icon {
    padding: 5px;
    color: #DBDCDD;
    font-size: 20px;
}

.message-edit-icon:hover,
.message-delete-icon:hover {
    cursor: pointer;
    background-color: #3A3E44;
}

.pointer-events-off {
    pointer-events: none;
}

.message-edit-form {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    position: relative;
}

.message-edit-input {
    background-color: #40444B;
    border-radius: 10px;
    width: 100%;
    color: #DCDDDE;
    resize: none;
    padding: 10px;
    padding-right: 50px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 10px;
}

.message-edit-input::-webkit-scrollbar {
    width: 0;
}

.message-edit-options-container {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}

.message-edit-cancel,
.message-edit-save {
    color: #D1CBC3;
    font-size: 12px;
}

.message-edit-dot {
    color: #D1CBC3;
    margin-left: 5px;
    margin-right: 5px;
}

.message-edit-cancel-button,
.message-edit-save-button {
    color: #1FA4D6;
}

.message-edit-cancel-button:hover,
.message-edit-save-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.message-edit-save-button {
    background-color: rgba(0, 0, 0, 0);
}

.message-edited-true {
    font-size: 11px;
    color: #6B6F75;
    margin-left: 3px;
}

.message-edit-error {
    color: #fd5f5f;
    font-size: 13px;
    margin-left: 20px;
}

.message-edit-character-counter {
    color: #9B979A;
    position: absolute;
    right: 40px;
    bottom: 0px;
    font-size: 12px;
}

.message-edit-counter-negative-true {
    color: #fd5f5f;
}

.emoji-selector-edit {
    position: absolute;
    right: 30px;
    top: 50%;
    z-index: 3;
    font-size: 24px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-filter: grayscale();
            filter: grayscale();
    transition: .1s;
}

.emoji-selector-edit:hover {
    cursor: pointer;
    -webkit-transform: translateY(-50%) scale(1.2);
            transform: translateY(-50%) scale(1.2);
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}

.delete-message-form {
    background-color: #303339;
    border-radius: 10px;
    padding: 15px;
    width: 500px;
    max-height: 600px;
}

.delete-message-content {
    overflow: auto;
    max-height: 400px;
    padding-right: 10px;
}

.delete-message-content::-webkit-scrollbar {
    background-color: rgba(255, 0, 0, 0);
    width: 10px;
}

.delete-message-content::-webkit-scrollbar-thumb {
    background-color: #232529;
    margin-left: 14px;
    border-radius: 100px;
}

.delete-message-header {
    color: white;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 20px;
}

.delete-message-subheader {
    color: rgb(236, 234, 234);
    font-weight: normal;
    margin-top: 5px;
}

.delete-message-card {
    border: 1px solid black;
    padding: 10px 8px;
    margin-top: 20px;
    display: flex;
}

.delete-message-profile-picture {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.delete-message-username-content {
    margin-left: 10px;
}

.delete-message-username {
    color: white;
    font-weight: 500;
}

.delete-message-datetime {
    color: #6B6F75;
    margin-left: 10px;
    font-size: 14px;
}

.delete-message-cancel-submit-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 15px;
    margin-right: 10px;
}

.delete-message-cancel {
    color: white;
    margin-right: 20px;
}

.delete-message-button {
    background-color: #ED4143;
    color: white;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
}

.delete-message-cancel:hover,
.delete-message-button:hover {
    cursor: pointer;
}

.user-info-card-container {
    position: absolute;
    left: -290px;
    top: 60px;
    height: 200px;
    width: 280px;
    background-color: #282a2e;
    border-radius: 10px;
    z-index: 5;
}

.user-info-card-banner {
    height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
}

.user-info-card-profile-picture {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    position: absolute;
    left: 20px;
    bottom: -50px;
    border: 6px solid #282a2e;
    object-fit: cover;
}

.user-info-online-status-online {
    width: 28px;
    height: 28px;
    background-color: #3dbe66;
    border: 6px solid #282a2e;
    border-radius: 50%;
    position: absolute;
    bottom: -45px;
    right: 170px;
}

.user-info-online-status-offline {
    width: 28px;
    height: 28px;
    background-color: #889294;
    border: 6px solid #282a2e;
    border-radius: 50%;
    position: absolute;
    bottom: -45px;
    right: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-info-online-status-offline-inner {
    width: 8px;
    height: 8px;
    background-color: #282a2e;
    border-radius: 50%;
}

.user-info-card-username-container {
    margin-top: 50px;
    padding-left: 15px;
}

.user-info-card-username {
    font-size: 24px;
    color: white;
    font-weight: 500;
}

.user-info-card-email {
    color: #889294;
    margin-top: 5px;
    font-size: 15px;
}

.online-user-container {
    width: 100%;
    margin-top: 6px;
    display: flex;
    align-items: center;
    padding: 3px 5px;
    padding-top: 4px;
    border-radius: 5px;
    /* position: relative; */
}

.online-user-profile-picture {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.online-user-image-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
}

.online-green-status-indicator {
    background-color: #3dbe66;
    border: 3px solid #2F3136;
    position: absolute;
    border-radius: 50%;
    bottom: 2px;
    right: 0px;
    width: 16px;
    height: 16px;
}

.online-user-username {
    font-size: 16px;
    font-weight: normal;
    color: #889294;
    margin-left: 15px;
}

.online-user-container:hover > .online-user-username {
    color: #DCDDD9;
    cursor: pointer;
}

.online-user-container:hover {
    background-color: #34373C;
    cursor: pointer;
}

.offline-user-container {
    width: 100%;
    margin-top: 6px;
    display: flex;
    align-items: center;
    padding: 3px 5px;
    padding-top: 4px;
    border-radius: 5px;
    /* position: relative; */
}

.offline-user-profile-picture {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-filter: brightness(45%);
            filter: brightness(45%);
}

.offline-user-username {
    font-size: 16px;
    font-weight: normal;
    color: #889294;
    -webkit-filter: brightness(60%);
            filter: brightness(60%);
    margin-left: 15px;
}

.offline-user-container:hover > .offline-user-username,
.offline-user-container:hover > .offline-user-profile-picture {
    color: #DCDDD9;
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
    cursor: pointer;
}

.offline-user-container:hover {
    background-color: #34373C;
    cursor: pointer;
}

.channel-content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.channel-content-header-container {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: solid 1px #202225;
    flex: 0 0 auto;
}

.channel-content-header-hashtag {
    color: #6B6F76;
    font-size: 28px;
    font-family: 'Vidaloka', serif;
    margin-left: 20px;
}

.channel-content-header {
    color: white;
    font-size: 18px;
    font-weight: normal;
    margin-left: 10px;
}

.channel-content-header-divider {
    color: #B9BBBE;
    font-size: 24px;
    font-weight: 200;
    margin-left: 10px;
}

.channel-content-topic {
    color: #B9BBBE;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 70%;
    margin-left: 10px;
    font-size: 14px;
}

.channel-content-messages-container {
    flex: auto;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.message-with-profile-pic-container {
    display: flex;
    margin-top: 20px;
    align-items: flex-start;
    width: 100%;
}

.message-with-profile-pic-container .channel-content-message {
    padding-left: 1.5px;
}

.message-profile-pic-container {
    height: 45px;
    width: 45px;
}

/* If a message is the no-profile-pic message */
.message-without-profile-pic-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.message-profile-standin {
    width: 47px;
    height: 100%;
    display: flex;
    justify-content: center;
}

.message-hover-time {
    font-size: 10px;
    color: #6B6F75;
    white-space: pre;
    width: 100%;
    margin-top: 8px;
    font-family: Helvetica, sans-serif;
}

/* ****************************************** */

.message-with-profile-pic-container,
.message-without-profile-pic-container {
    padding-left: 20px;
    padding-right: 5px;
    position: relative;
}

.message-with-profile-pic-container:hover,
.message-without-profile-pic-container:hover {
    background-color: #32353B;
    
}

.message-profile-pic {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.username-message-container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 100%;
    padding-right: 10px;
}

.message-username {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin-top: 3px;
}

.message-date-time {
    color: #6B6F75;
    margin-left: 10px;
    font-size: 14px;
}

.channel-content-message {
    color: #DCDDDE;
    margin-top: 5px;
    word-break: break-word;
}

.channel-content-chat-input-container {
    height: 100px;
    width: 100%;
    flex: 0 0 auto;
}

.new-message-form {
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
}

.new-message-label {
    width: 100%;
    height: 100%;
    position: relative;
}

.new-message-input {
    background-color: #40444B;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    color: #DCDDDE;
    resize: none;
    padding: 10px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 22px;
    box-sizing: border-box;
}

.message-character-counter {
    color: #9B979A;
    position: absolute;
    right: 10px;
    bottom: 3px;
    font-size: 12px;
}

.message-counter-negative-true {
    color: #fd5f5f;
}

.new-message-input::-webkit-scrollbar {
    background-color: rgba(255, 0, 0, 0);
    width: 10px;
}

.channel-content-messages-container::-webkit-scrollbar {
    background-color: rgba(255, 0, 0, 0);
    width: 10px;
}

.channel-content-messages-container::-webkit-scrollbar-thumb {
    background-color: #232529;
    margin-left: 2px;
    border-radius: 100px;
}

.message-error {
    color: #fd5f5f;
    font-size: 13px;
    margin-top: 5px;
    position: absolute;
    bottom: -16px;
}

.emoji-selector {
    position: absolute;
    left: 30px;
    top: 38px;
    z-index: 3;
    font-size: 24px;
    -webkit-filter: grayscale();
            filter: grayscale();
    transition: .1s;
}

.emoji-selector:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}

.channel-content-outer-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.users-list-container {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.users-list {
    height: 100%;
    width: 100%;
    background-color: #2F3136;
    padding: 15px;
    overflow: auto;
}

.users-list::-webkit-scrollbar {
    background-color: rgba(255, 0, 0, 0);
    width: 10px;
}

.users-list::-webkit-scrollbar-thumb {
    background-color: #232529;
    margin-left: 2px;
    border-radius: 100px;
}

.online-users-header,
.offline-users-header {
    font-size: 13.5px;
    color: #889294;
    font-weight: 500;
}

.offline-users-header {
    margin-top: 25px;
}

.home-friends-header {
    border-bottom: solid 1px #202225;
    height: 55px;
    display: flex;
    align-items: center;
}

.home-page-header {
    color: white;
    font-weight: normal;
    font-size: 18px;
    margin-left: 20px;
}

.friends-button-container {
    display: flex;
    align-items: center;
    margin-left: 7px;
    margin-right: 7px;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}

.friends-icon {
    color: #6B6F76;
}

.friends-button-text {
    color: #6B6F76;
    font-weight: 500;
    margin-left: 10px;
}

.friends-container-active-true {
    background-color: #393C43;
}

.friends-icon-active-true,
.friends-button-text-active-true {
    color: white;
}

.friends-button-container:hover {
    background-color: #393C43;
    cursor: pointer;
}

.friends-button-container:hover > .friends-button-text,
.friends-button-container:hover > .friends-icon {
    color: white;
}

.friend-card-container {
    border-top: 1px solid #3A3E44;
    display: flex;
    padding: 10px;
    align-items: center;
    border-radius: 10px;
    width: 80%;
}

.friend-card-container:hover {
    background-color: #3A3E44;
}

.friend-card-container:hover > .friend-options-container {
    background-color: #282b30;
}

.friend-image-container {
    position: relative;
}

.friend-profile-picture {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.friend-online-status-indicator-true {
    position: absolute;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    background-color: #3dbe66;
    border: 3px solid #36393F;
}

.friend-online-status-indicator-false {
    position: absolute;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    background-color: #889294;
    border: 3px solid #36393F;
    display: flex;
    justify-content: center;
    align-items: center;
}

.friend-online-status-indicator-false > .offline-friend-inner {
    background-color: #36393F;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

.friend-info-container {
    margin-left: 10px;
}

.friend-username {
    font-weight: 500;
    color: white;
    font-size: 15px;
}

.friend-online-status {
    color: #B9BBBE;
    font-size: 14px;
}

.friend-username:hover,
.friend-online-status:hover {
    cursor: default;
}

.friend-options-container {
    height: 38px;
    width: 38px;
    background-color: #2f3236;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: auto;
    position: relative;
}

.friend-options-icon {
    color: rgb(179, 179, 179);
    font-size: 20px;
}

.friend-options-container:hover {
    cursor: pointer;
}

.friend-options-menu-container {
    background-color: #27292e;
    position: absolute;
    right: 40px;
    top: 20px;
    height: 50px;
    width: 150px;
    padding: 5px 10px;
    border-radius: 5px;
}

.remove-friend-button-container {
    padding: 5px 10px;
    border-radius: 5px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.remove-friend-button-container:hover {
    background-color: #ED4245;
}

.remove-friend-button {
    color: #ED4245;
}

.remove-friend-button-container:hover > .remove-friend-button {
    color: white;
}

.remove-friend-confirm-container {
    height: 220px;
    width: 450px;
    background-color: #31343a;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.remove-friend-confirm-header {
    font-size: 20px;
    color: white;
    font-weight: 500;
    padding: 0 15px;
    margin-top: 20px;
}

.remove-friend-confirm-message {
    margin-top: 20px;
    color: #B9BBBE;
    font-size: 15px;
    padding: 0 15px;
}

.remove-friend-confirm-username {
    color:#dbdbdb;
    font-weight: 500;
}

.remove-friend-options-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: auto;
    background-color: #292b30;
    height: 70px;
}

.remove-friend-cancel-button-container,
.remove-friend-confirm-button-container {
    padding: 10px 15px;
    border-radius: 3px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.remove-friend-cancel-button-container:hover,
.remove-friend-confirm-button-container:hover {
    cursor: pointer;
}


.remove-friend-cancel-button-container {
    margin-right: 10px;
}

.remove-friend-confirm-button-container {
    margin-right: 15px;
    background-color: #ED4245;
}

.remove-friend-cancel-text,
.remove-friend-confirm-text {
    color: white;
    font-size: 14px;
}

.nonfriend-card-container {
    border-top: 1px solid #3A3E44;
    display: flex;
    padding: 10px;
    align-items: center;
    border-radius: 10px;
    width: 100%;
}

.nonfriend-card-container:hover {
    background-color: #3A3E44;
}

.nonfriend-card-container:hover > .nonfriend-options-container {
    background-color: #282b30;
}

.nonfriend-image-container {
    position: relative;
}

.nonfriend-profile-picture {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.nonfriend-online-status-indicator-true {
    position: absolute;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    background-color: #3dbe66;
    border: 3px solid #36393F;
}

.nonfriend-online-status-indicator-false {
    position: absolute;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    background-color: #889294;
    border: 3px solid #36393F;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nonfriend-online-status-indicator-false > .offline-nonfriend-inner {
    background-color: #36393F;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

.nonfriend-info-container {
    margin-left: 10px;
}

.nonfriend-username {
    font-weight: 500;
    color: white;
    font-size: 15px;
}

.nonfriend-online-status {
    color: #B9BBBE;
    font-size: 14px;
}

.nonfriend-username:hover,
.nonfriend-online-status:hover {
    cursor: default;
}


.add-friend-button {
    margin-left: auto;
    height: 30px;
    padding: 0 15px;
    font-size: 14px;
    background-color: #5865F2;
    color: white;
    font-weight: 500;
    border-radius: 3px;
    margin-right: 15px;
}

.cancel-friend-request-button {
    margin-left: auto;
    height: 30px;
    padding: 0 15px;
    font-size: 14px;
    background: none;
    color: white;
    font-weight: 500;
    border-radius: 3px;
    margin-right: 15px;
}

.add-friend-button:hover,
.cancel-friend-request-button:hover {
    cursor: pointer;
}

.friend-request-container {
    background-color: #3A3E44;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    width: 70%;
    margin-top: 10px;
}

.friend-request-profile-picture {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.friend-request-info-container {
    margin-left: 10px;
}

.friend-request-username {
    color: white;
    font-size: 16px;
    font-weight: 500;
}

.friend-request-notifier-message {
    color: #B9BBBE;
    font-size: 14px;
}

.friend-request-username:hover,
.friend-request-notifier-message:hover {
    cursor: default;
}

.friend-request-options-container {
    margin-left: auto;
    display: flex;
}

.friend-request-accept-container,
.friend-request-decline-container {
    background-color: #202225;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
}

.friend-request-accept-container {
    margin-right: 12px;
}

.friend-request-accept-container:hover,
.friend-request-decline-container:hover {
    cursor: pointer;
}

.friend-request-accept-icon,
.friend-request-decline-icon {
    color: rgb(179, 179, 179);
}

.friends-content-container {
    height: 100%;
    width: 100%;
}

.friends-content-navigator {
    border-bottom: solid 1px #202225;
    display: flex;
    height: 55px;
    padding: 15px;
    align-items: center;
}

.navigator-friends-icon {
    color: #6B6F76;
}

.navigator-friends-button-text {
    color: white;
    font-weight: 500;
    font-size: 17px;
}

.navigator-divider {
    height: 25px;
    border-left: 1px solid #6b6f7665;
    margin-left: 15px;
}

.friends-link-container {
    margin-left: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.friends-navigator-link {
    color: #B9BBBE;
}

.friends-link-container:hover {
    background-color: #3A3E44;
    cursor: pointer;
}

.friend-category-true {
    background-color: #464952;
}

.friend-link-true {
    color: white;
}

.friends-pending-container {
    display: flex;
}

.pending-requests-count {
    margin-left: 8px;
    background-color: #ED4245;
    padding: 3px;
    height: 20px;
    width: 20px;
    color: white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.add-friend-container {
    background-color: #3BA55D;
    color: white;
    margin-left: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.add-friend-container:hover {
    cursor: pointer;
}

.add-friend-category-true {
    background-color: #3ba55d00;
    color: #3BA55D;
}

.add-friend-category-true:hover {
    background-color: #3A3E44;
}

.friends-lists-container {
    padding: 20px;
    height: 70%;
}

.online-friends-container,
.all-friends-container,
.pending-friends-container {
    height: 100%;
    overflow: auto;
}

.online-friends-container::-webkit-scrollbar,
.all-friends-container::-webkit-scrollbar,
.pending-friends-container::-webkit-scrollbar {
    background-color: rgba(255, 0, 0, 0);
    width: 10px;
}

.online-friends-container::-webkit-scrollbar-thumb,
.all-friends-container::-webkit-scrollbar-thumb,
.pending-friends-container::-webkit-scrollbar-thumb {
    background-color: #232529;
    margin-left: 2px;
    border-radius: 100px;
}

.friends-status-header {
    font-size: 13.5px;
    color: #889294;
    font-weight: 500;
    margin-bottom: 10px;
}

.no-friend-requests-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-friend-header {
    color: white;
    font-weight: 500;
    font-size: 18px;
    width: 80%;
    margin: 0 auto;
}

.add-friend-message {
    color: #B9BBBE;
    font-size: 15px;
    width: 80%;
    margin: 20px auto;
}

.add-friend-form {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.add-friend-input {
    height: 50px;
    width: 100%;
    border-radius: 10px;
    background-color: #303339;
    padding-left: 10px;
    border: 1px solid rgb(29, 29, 29);
    color: #D2D3D4;
}

.add-friend-input:focus {
    border: 1px solid #00AFF4;
}

.friend-search-results {
    max-height: 80%;
    overflow: auto;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
}

.friend-search-results::-webkit-scrollbar {
    background-color: rgba(255, 0, 0, 0);
    width: 10px;
}

.friend-search-results::-webkit-scrollbar-thumb {
    background-color: #232529;
    margin-left: 2px;
    border-radius: 100px;
}

.friend-search-result {
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid;
}

.add-friend-button {
    margin-left: auto;
    height: 30px;
    padding: 0 15px;
    font-size: 14px;
    background-color: #5865F2;
    color: white;
    font-weight: 500;
    border-radius: 3px;
    margin-right: 15px;
}

.cancel-friend-request-button {
    margin-left: auto;
    height: 30px;
    padding: 0 15px;
    font-size: 14px;
    background: none;
    color: white;
    font-weight: 500;
    border-radius: 3px;
    margin-right: 15px;
}

.add-friend-button:hover,
.cancel-friend-request-button:hover {
    cursor: pointer;
}

.dashboard-page-container {
    height: 100vh;
    width: 100vw;
    display: flex;
}

.server-container {
    width: 85px;
    height: 100vh;
    flex: 0 0 auto;
    background-color: #202225;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.server-container::-webkit-scrollbar {
    background-color: rgba(255, 0, 0, 0);
    width: 10px;
}

.server-container::-webkit-scrollbar-thumb {
    background-color: #3c3f46;
    margin-left: 2px;
    border-radius: 100px;
}

.home-button-divider {
    width: 30px;
    border: 1px solid #2F3136;
    margin-top: 10px;
}

.channel-container {
    width: 250px;
    height: 100vh;
    flex: 0 0 auto;
    background-color: #2F3136;
    position: relative;
}

.server-name-container {
    border-bottom: solid 1px #202225;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    transition: .3s;
    position: relative;
}

.server-name-container:hover {
    cursor: pointer;
    background-color: #393C43;
}

.server-name {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 90%;
}

.server-options-icon-container {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.server-options-icon {
    color: #D7D7D8;
}

.text-channels-container {
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.text-channels-header-container {
    display: flex;
    align-items: center;
}

.text-channels-header {
    color: #6B6F76;
    font-size: 14px;
    font-weight: 600;
    cursor: default;
}

.text-channels-header-container:hover > p {
    color: #D4D5D6;
    font-weight: normal;
}

.new-text-channel-button-container {
    margin-left: auto;
}

.new-channel-button {
    color: #6B6F76;
    font-size: 20px;
}

.new-channel-button:hover {
    cursor: pointer;
    color: #D4D5D6;
}

.session-user-container {
    display: flex;
    position: absolute;
    bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    width: 100%;
}

.session-user-profile-pic {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    object-fit: cover;
}

.session-user-username {
    color: white;
    font-family: 'Catamaran', sans-serif;
    font-weight: 600;
    font-size: 15px;
    margin-left: 10px;
}

.session-user-settings-icon {
    color: #BDBFC1;
    margin-left: auto;
    font-size: 22px;
}

.profile-dropdown {
    background-color: #202225;
    width: 100px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    bottom: 40px;
    box-shadow: 0 0 0 1px rgb(32 34 37 / 60%), 0 2px 10px 0 rgb(0 0 0 / 20%);
    border: 1px solid rgb(24, 22, 22);
}

.logout-button {
    color: #C93A3C;
}

.profile-dropdown:hover,
.logout-button:hover {
    cursor: pointer;
}

.session-user-settings-icon:hover {
    cursor: pointer;
}

.session-user-username:hover {
    cursor: default;
}

.chat-container {
    flex: auto;
    background-color: #36393F;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

