/* TODO Add site wide styles */
/* site wide styles */
@import "normalize.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-family: 'Roboto', sans-serif;
    color: #3E3E3E;
}

h1 {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: black;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #D2D3D4;
    transition: background-color 5000s ease-in-out 0s;
}

div {
    box-sizing: border-box;
}

.loading-background {
    height: 100vh;
    width: 100vw;
    background-color: #272A31;
    display: flex;
    justify-content: center;
    align-items: center;
}
