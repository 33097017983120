@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300&family=Paytone+One&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300&family=Paytone+One&family=Roboto:wght@100;300;400&display=swap');

.dashboard-page-container {
    height: 100vh;
    width: 100vw;
    display: flex;
}

.server-container {
    width: 85px;
    height: 100vh;
    flex: 0 0 auto;
    background-color: #202225;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.server-container::-webkit-scrollbar {
    background-color: rgba(255, 0, 0, 0);
    width: 10px;
}

.server-container::-webkit-scrollbar-thumb {
    background-color: #3c3f46;
    margin-left: 2px;
    border-radius: 100px;
}

.home-button-divider {
    width: 30px;
    border: 1px solid #2F3136;
    margin-top: 10px;
}

.channel-container {
    width: 250px;
    height: 100vh;
    flex: 0 0 auto;
    background-color: #2F3136;
    position: relative;
}

.server-name-container {
    border-bottom: solid 1px #202225;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    transition: .3s;
    position: relative;
}

.server-name-container:hover {
    cursor: pointer;
    background-color: #393C43;
}

.server-name {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: fit-content;
    max-width: 90%;
}

.server-options-icon-container {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.server-options-icon {
    color: #D7D7D8;
}

.text-channels-container {
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.text-channels-header-container {
    display: flex;
    align-items: center;
}

.text-channels-header {
    color: #6B6F76;
    font-size: 14px;
    font-weight: 600;
    cursor: default;
}

.text-channels-header-container:hover > p {
    color: #D4D5D6;
    font-weight: normal;
}

.new-text-channel-button-container {
    margin-left: auto;
}

.new-channel-button {
    color: #6B6F76;
    font-size: 20px;
}

.new-channel-button:hover {
    cursor: pointer;
    color: #D4D5D6;
}

.session-user-container {
    display: flex;
    position: absolute;
    bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    width: 100%;
}

.session-user-profile-pic {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    object-fit: cover;
}

.session-user-username {
    color: white;
    font-family: 'Catamaran', sans-serif;
    font-weight: 600;
    font-size: 15px;
    margin-left: 10px;
}

.session-user-settings-icon {
    color: #BDBFC1;
    margin-left: auto;
    font-size: 22px;
}

.profile-dropdown {
    background-color: #202225;
    width: 100px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    bottom: 40px;
    box-shadow: 0 0 0 1px rgb(32 34 37 / 60%), 0 2px 10px 0 rgb(0 0 0 / 20%);
    border: 1px solid rgb(24, 22, 22);
}

.logout-button {
    color: #C93A3C;
}

.profile-dropdown:hover,
.logout-button:hover {
    cursor: pointer;
}

.session-user-settings-icon:hover {
    cursor: pointer;
}

.session-user-username:hover {
    cursor: default;
}

.chat-container {
    flex: auto;
    background-color: #36393F;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
