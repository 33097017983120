@import url('https://fonts.googleapis.com/css2?family=Vidaloka&display=swap');

.channel-name-container {
    color: #6B6F76;
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 3px;
    margin-top: 3px;
}

.channel-name-container:hover {
    background-color: #393C43;
    cursor: pointer;
}

.channel-settings-container {
    margin-left: auto;
    width: 20px;
    height: 20px;
}

.channel-name-hashtag {
    color: #6B6F76;
    margin-right: 5px;
    font-family: 'Vidaloka', serif;
    font-size: 20px;
}

.channel-name {
    color: #6B6F76;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
}

.channel-name-container:hover > p {
    color: white;
}

.channel-name:hover {
    cursor: pointer;
}

.channel-settings-icon {
    color: #B9BBBE;
    font-size: 20px;
}

.channel-settings-icon:hover {
    cursor: pointer;
}

.channel-selected-true {
    background-color: #393C43;
}

.channel-selected-true > p {
    color: white;
}
