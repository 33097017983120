.delete-server-form {
    border: 1px solid;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 22rem;
    padding: 30px 60px;
    background-color: #36393F;
    border-radius: 10px;
}

.delete-server-header {
    color: white;
    font-size: 20px;
    font-weight: normal;
    font-weight: 600;
    text-shadow: 1px 1px 0px rgb(0, 0, 0);
}

.delete-server-confirm-message {
    color: white;
    font-size: 16px;
    margin-top: 30px;
}

.delete-server-confirm-message-name {
    color: white;
    font-weight: 600;
    text-shadow: 1px 1px 0px rgb(0, 0, 0);
}

.delete-server-enter-name-label {
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 15px;
}

.delete-server-enter-name-input {
    margin-top: 5px;
    background-color: #303339;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #000000;
    color: #D2D3D4;
    padding-left: 10px;
    transition: .3s;
}

.delete-server-enter-name-input:hover,
.delete-server-enter-name-input:focus {
    border: 1px solid #00AFF4;
}

.delete-server-cancel-submit-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
}

.delete-server-cancel {
    color: white;
    margin-right: 20px;
}

.delete-server-cancel:hover {
    cursor: pointer;
}

.delete-server-button {
    background-color: #ED4143;
    color: white;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    transition: .3s;
}

.delete-server-button:hover {
    cursor: pointer;
}

.delete-server-disabled-true {
    background-color: #fa8383;
    color: rgb(224, 190, 190);
}

.delete-server-disabled-true:hover {
    cursor: not-allowed;
}
