@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=Roboto&display=swap');

.navbar-container {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 20px;
}

.navbar-logo-container {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 40px;
  width: 55px;
}

.navbar-header {
  color: white;
  font-size: 26px;
  font-style: normal;
  font-weight: normal;
  line-height: 35px;
  margin-left: 10px;
  font-family: 'Paytone One', sans-serif;
}

.navbar-login {
  padding: 10px 15px;
  border-radius: 50px;
  background-color: white;
  transition: 0.3s;
}

.navbar-login:hover {
  box-shadow: 0px 10px 48px -5px rgba(0, 0, 0, 0.30);
  color: #5865F2;
}

/* .logout-button {
  color: rgb(250, 250, 250);
  background-color: rgb(255, 95, 95);
  padding: 8px 11px;
  border-radius: 5px;
} */
